// Role types and roles
export const RoleType = {
  HUMAN: 'human',
  AI: 'ai',
  SYSTEM: 'system',
  TOOL: 'tool',
};

export const Role = {
  USER: 'user',
  ASSISTANT: 'assistant',
  MODERATOR: 'moderator',
  SYSTEM: 'system',
  EXTERNAL_SERVICE: 'external_service',
};

// Message sources
export const Sources = {
  STREAMCHAT: 'streamchat',
  WEBSOCKET: 'websocket',
  TELEGRAM: 'telegram',
  DISCORD: 'discord',
  PLATFORM: 'platform',
};

// Message event types
export const MessageEvents = {
  MESSAGE: 'message',
  CONTROL: 'control',
  HEARTBEAT: 'heartbeat',
}; 