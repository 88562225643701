import React from 'react';
import PropTypes from 'prop-types';

const ToggleButton = ({ visible, onClick, avatarImage, position }) => {
  if (!visible) return null;
  
  return (
    <button
      id="toggle-chat-button"
      className="toggle-button"
      aria-label="Toggle chat"
      onClick={onClick}
      style={position}
    >
      <img 
        src={avatarImage} 
        alt="Chat toggle" 
        className="toggle-button-img"
      />
    </button>
  );
};

ToggleButton.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  avatarImage: PropTypes.string.isRequired,
  position: PropTypes.object.isRequired,
};

export default ToggleButton; 