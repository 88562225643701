import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { formatTimestamp } from './utils';
import Message from './Message';
import ToggleButton from './ToggleButton';

const ChatWidgetContainer = ({
  config,
  isVisible,
  toggleChat,
  messages,
  streamingMessage,
  onSubmit,
  onFollowUpClick,
  connectionError,
}) => {
  const messagesContainerRef = useRef(null);
  const messageInputRef = useRef(null);
  const formRef = useRef(null);

  // Scroll to bottom whenever messages update or streaming message changes
  useEffect(() => {
    if (messagesContainerRef.current) {
      scrollToBottom(true);
    }
  }, [messages, streamingMessage]);

  // Focus input when chat becomes visible
  useEffect(() => {
    if (isVisible && messageInputRef.current) {
      messageInputRef.current.focus();
    }
  }, [isVisible]);

  const scrollToBottom = (force = false) => {
    if (!messagesContainerRef.current) return;

    // Check if we're already near the bottom before scrolling
    const container = messagesContainerRef.current;
    const isNearBottom =
      container.scrollHeight - container.scrollTop - container.clientHeight <
      100;

    if (force || isNearBottom) {
      requestAnimationFrame(() => {
        container.scrollTop = container.scrollHeight;
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const text = messageInputRef.current.value.trim();

    if (text) {
      onSubmit(text);
      messageInputRef.current.value = '';
      // Temporarily disable the input to prevent multiple submissions
      messageInputRef.current.disabled = true;
      setTimeout(() => {
        if (messageInputRef.current) {
          messageInputRef.current.disabled = false;
          messageInputRef.current.focus();
        }
      }, 1000);
    }
  };

  // Position the widget based on config
  const getPositionStyles = () => {
    const position = {};
    const anchor = config.position.anchor;

    switch (anchor) {
      case 'bottom-right':
        position.bottom = `${config.position.bottom}px`;
        position.right = `${config.position.right}px`;
        break;
      case 'bottom-left':
        position.bottom = `${config.position.bottom}px`;
        position.left = `${config.position.right}px`;
        break;
      case 'top-right':
        position.top = `${config.position.bottom}px`;
        position.right = `${config.position.right}px`;
        break;
      case 'top-left':
        position.top = `${config.position.bottom}px`;
        position.left = `${config.position.right}px`;
        break;
      default:
        position.bottom = `${config.position.bottom}px`;
        position.right = `${config.position.right}px`;
    }

    return position;
  };

  const renderMessages = () => {
    return (
      <>
        {messages.map((msg) => (
          <Message
            key={msg.id || Math.random().toString(36).substring(2, 9)}
            message={msg}
            onFollowUpClick={onFollowUpClick}
          />
        ))}

        {streamingMessage && (
          <Message
            key={`streaming-${streamingMessage.id}`}
            message={streamingMessage}
            isStreaming={true}
            onFollowUpClick={onFollowUpClick}
          />
        )}
      </>
    );
  };

  return (
    <>
      <div
        className={`chat-widget-container ${isVisible ? 'visible' : ''}`}
        style={getPositionStyles()}
      >
        <div className="chat-header">
          <h2 className="chat-title">{config.widgetTitle}</h2>
          <button
            onClick={() => toggleChat(false)}
            className="close-button"
            aria-label="Close chat"
          >
            ×
          </button>
        </div>

        {connectionError && (
          <div className="error-message">{connectionError}</div>
        )}

        <div className="messages-container">
          <div className="messages-list">{renderMessages()}</div>
        </div>

        <form className="message-form" onSubmit={handleSubmit}>
          <div className="input-container">
            <input
              type="text"
              id="message-input"
              name="message"
              placeholder="Type a message..."
              autoComplete="off"
            />
            <button type="submit" className="send-button">
              Send
            </button>
          </div>
        </form>
      </div>
      <ToggleButton
        config={config}
        onClick={() => toggleChat(true)}
        isVisible={!isVisible}
      />
    </>
  );
};

ChatWidgetContainer.propTypes = {
  config: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  toggleChat: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  streamingMessage: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onFollowUpClick: PropTypes.func.isRequired,
  connectionError: PropTypes.string,
};

export default ChatWidgetContainer;
