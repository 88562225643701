export const createIntercomUserSignupFormData = ({
  name,
  email,
  message,
  tagId,
}) => {
  return JSON.stringify({
    name,
    email,
    message,
    tagId,
  });
};
