import React, { useState } from 'react';
import { Link } from 'gatsby';
import Button from '../atoms/inputs/Button';
import { useLocation } from '@reach/router';
import { createIntercomUserSignupFormData } from '../../utils/helpers/intercom';
export default function JoinTheWaitlist() {
  const { pathname } = useLocation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const inputStyles =
    'block w-full px-4 py-3 h-12 appearance-none rounded-3xl border-0 text-sm bg-grey-gradient text-grey-25 placeholder-grey-25 focus:outline-none focus:ring-1 focus:ring-highlight shadow-md';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Clear previous messages
    setErrorMessage('');
    setSuccessMessage('');

    if (!name || !email) {
      setIsLoading(false);
      setErrorMessage('Please fill in all fields: Name and Email.');
      return;
    }

    try {
      console.log('Submitting form data:', { name, email, tagId: '10711522' });
      const KOKO_WAITLIST_TAG_ID = '10711522';
      const response = await fetch('/.netlify/functions/intercom-user-signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: createIntercomUserSignupFormData({
          name,
          email,
          message: `Hi, my name is ${name} and I want to join the waitlist to chat with Koko's AI. My email is ${email}.`,
          tagId: KOKO_WAITLIST_TAG_ID,
        }),
      });

      const responseData = await response.json().catch(() => ({}));
      console.log('Form submission response:', {
        status: response.status,
        data: responseData,
      });

      setIsLoading(false);

      if (response.ok) {
        setName('');
        setEmail('');
        setSuccessMessage('Thank you for joining the waitlist!');

        // Track the successful submission event
        if (window?.gtag) {
          window.gtag('event', 'koko_waitlist_submission', {
            event_category: 'Waitlist',
            event_label: 'SXSW Koko Waitlist',
          });
        }
      } else {
        if (response.status === 409) {
          setErrorMessage("You're already on our waitlist!");
        } else if (response.status >= 400 && response.status < 500) {
          setErrorMessage(
            responseData.message || 'Please check your input and try again.'
          );
        } else {
          setErrorMessage(
            'An error occurred on our server. Please try again later.'
          );
        }
        console.error('Form submission error:', response.status, responseData);
      }
    } catch (error) {
      console.error('Form submission exception:', error);
      setIsLoading(false);
      setErrorMessage(
        'Network error. Please check your connection and try again.'
      );
    }
  };

  return (
    <div id="Koko-SXSW-Waitlist" className="w-full mx-auto">
      <form onSubmit={handleSubmit} className="w-3/4 mx-auto md:mx-0">
        <div className="flex flex-col md:flex-row items-center justify-center md:justify-start w-full gap-4">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className={inputStyles}
            placeholder="Name"
          />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={inputStyles}
            placeholder="Email"
          />
        </div>

        <div className="w-full py-2 flex justify-center md:justify-start">
          <Button
            type="submit"
            label="Join Waitlist"
            className="!bg-primary !text-base font-medium hover:!bg-primary/90 !from-primary !to-primary w-full"
            isLoading={isLoading}
            id="Koko-SXSW-Waitlist-btn"
          />
        </div>
        <p
          className={`text-xs lg:text-base text-white font-light text-center md:text-left`}
        >
          By signing up, you agree to our{' '}
          <Link to="/legal/terms" className="text-white font-bold">
            terms of service
          </Link>
          .
        </p>
        {successMessage && (
          <p className="my-4 lg:my-6 text-green-400 text-sm md:text-md text-center md:text-left">
            {successMessage}
          </p>
        )}
        {errorMessage && (
          <p className="my-4 lg:my-6 text-white text-sm md:text-md text-center md:text-left">
            {errorMessage}
          </p>
        )}
      </form>
    </div>
  );
}
