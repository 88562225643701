import React from 'react';
import PropTypes from 'prop-types';
import { formatTimestamp } from './utils';

const Message = ({ message, isStreaming, onFollowUpClick }) => {
  const {
    role_type,
    message: content,
    metadata,
    created_at,
    isControl,
  } = message;

  // Debug output for follow-up questions
  console.log(`Message component rendering:`, {
    id: message.id,
    role: role_type,
    hasMetadata: !!metadata,
    followUpQuestions: metadata?.follow_up_questions,
    isStreaming,
  });

  // Create the appropriate class names
  const messageClasses = [
    'message',
    role_type || 'unknown',
    isStreaming ? 'streaming' : '',
    isControl ? 'control' : '',
  ]
    .filter(Boolean)
    .join(' ');

  // Render the message content
  return (
    <div className={messageClasses}>
      <div className="message-content">
        {content}
        {isStreaming && <span className="cursor"></span>}

        {/* Render follow-up questions if they exist and message is not streaming */}
        {!isStreaming && metadata?.follow_up_questions?.length > 0 && (
          <div className="follow-up-questions">
            {metadata.follow_up_questions.map((question, index) => (
              <button
                key={index}
                className="follow-up-pill"
                onClick={() => onFollowUpClick(question)}
              >
                {question}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="timestamp">{formatTimestamp(created_at)}</div>
    </div>
  );
};

Message.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string,
    role_type: PropTypes.string,
    message: PropTypes.string,
    metadata: PropTypes.object,
    created_at: PropTypes.string,
    isControl: PropTypes.bool,
  }).isRequired,
  isStreaming: PropTypes.bool,
  onFollowUpClick: PropTypes.func,
};

Message.defaultProps = {
  isStreaming: false,
  onFollowUpClick: () => {},
};

export default Message;
