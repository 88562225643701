import { v4 as uuidv4 } from 'uuid';

export const defaultConfig = {
  sessionId: typeof window !== 'undefined' ? uuidv4() : '',
  twinId: '6f446c10-ece9-49e7-8c83-90dbb537055f',
  websocketUrl: 'wss://staging-api.flockx.io/v2/websockets/',
  aiAvatarImage: 'https://res.cloudinary.com/fetch-ai/image/upload/v1737975796/flockx-community-app/Community%20AI%20Assets/Avatar/KokoAI_mgjy8u.jpg',
  widgetTitle: 'Welcome to YOUR APPS AI',
  position: {
    bottom: 30,
    right: 30,
    anchor: 'bottom-right', // Options: 'bottom-right', 'bottom-left', 'top-right', 'top-left'
  },
  reconnectAttempts: 5,
  reconnectBaseTimeout: 1000,
  theme: {
    // Primary colors
    primaryColor: '#1f2937',
    secondaryColor: '#4f46e5',
    backgroundColorStart: '#007bff',
    backgroundColorEnd: '#f8fafc',
    
    // Text colors
    textPrimary: '#1f2937',
    textSecondary: '#7c8a97',
    
    // System message styling
    systemMessageColor: '#ffffff',
    systemMessageBgColor: '#7c8a9705',
    systemMessageBorderColor: '#ffffff00',
    systemMessageFontStyle: 'normal',
    systemMessageFontSize: '0.875rem',
    systemMessageAlignment: 'center',
    
    // Follow-up pill styling
    followUpPillBgColor: '#e9f0ff',
    followUpPillHoverBgColor: '#c7dbff',
    followUpPillTextColor: '#0055cc',
    followUpPillBorderColor: '#a8c7fa',
    followUpPillBorderRadius: '1rem',
    
    // Message bubbles
    userMessageBg: '#4f46e5',
    userMessageText: '#ffffff',
    aiMessageBg: '#f9fafb',
    aiMessageText: '#1f2937',
    
    // Font settings
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontSize: '15px',
    headerFontSize: '18px',
    
    // Button styles
    buttonBg: '#4f46e5',
    buttonText: '#ffffff',
    buttonHoverBg: '#4f46e5',
    
    // Input styles
    inputBg: '#ffffff',
    inputText: '#1f2937',
    inputBorder: '#e5e7eb',
    
    // Basic colors (for compatibility with the original code)
    primaryHoverColor: '#4f46e5',
    bgColor: '#ffffff',
    textColor: '#1f2937',
    borderColor: '#e5e7eb',
    messageBgColor: '#f9fafb',
    
    // Message colors (for compatibility with the original code)
    aiMessageColor: '#1f2937',
    humanMessageColor: '#1f2937',
    
    // Gradient colors (for compatibility with the original code)
    gradientStart: 'rgba(99, 102, 241, 0.1)',
    gradientEnd: '#f8fafc'
  }
}; 