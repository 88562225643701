import React from 'react';
import { graphql } from 'gatsby';
import BaseLayout from '../../components/templates/BaseLayout';
import SEO from '../../components/atoms/utility/seo';
import JoinTheWaitlistKoko from '../../components/organisms/JoinTheWaitlistKoko';
import ChatWidget from '../../components/ChatWidget';





const staticSchemaMarkupData = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'flockx',
  logo: 'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1679063482/flockx-website/Logos/flockx-logo-google_xb9ted.png',
  image:
    'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1679063482/flockx-website/Logos/flockx-logo-google_xb9ted.png',
  sameAs: [
    'https://twitter.com/flockxofficial',
    'https://flockx.io',
    'https://www.linkedin.com/company/flockxofficial/',
  ],
  keywords: [
    'AI Agent',
    'Grand Rapids Gold',
    'buckets',
    'flockx',
  ].toString(),
  founder: 'Humayun Sheikh',
  slogan: 'Find Your Flock',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '99 Monroe',
    addressLocality: 'Grand Rapids',
    addressRegion: 'MI',
    postalCode: '49503',
    addressCountry: 'USA',
    floor: '2nd Floor',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 30.405504665093982,
    longitude: -97.72119716931535,
  },
};

const SEO_KEYWORDS = [
  'Grand Rapids Gold',
  'buckets',
  'flockx',
  'ai agent',
  'agentic',
  'multi agent',
  'personal ai agent',
  'knowledge base',
];

export default function HomePage({
  data: {
    site: {
      siteMetadata: { description, siteUrl, title },
    },
  },
}) {
  const schemaMarkup = {
    ...staticSchemaMarkupData,
    description,
    url: siteUrl,
  };

  // Configuration for the ChatWidget component
  const chatWidgetConfig = {
    twinId: "6f446c10-ece9-49e7-8c83-90dbb537055f",
    websocketUrl: "wss://staging-api.flockx.io/v2/websockets/",
    aiAvatarImage: "https://res.cloudinary.com/fetch-ai/image/upload/v1737975796/flockx-community-app/Community%20AI%20Assets/Avatar/KokoAI_mgjy8u.jpg",
    position: {
      bottom: 100,
      right: 25,
      anchor: 'bottom-right',
    },
    widgetTitle: 'Welcome to YOUR APPS AI',
    theme: {
      // Primary colors
      primaryColor: '#1f2937',
      secondaryColor: '#4f46e5',
      backgroundColorStart: '#007bff',
      backgroundColorEnd: '#f8fafc',
      
      // Text colors
      textPrimary: '#1f2937',
      textSecondary: '#7c8a97',
      
      // System message styling
      systemMessageColor: '#ffffff',
      systemMessageBgColor: '#7c8a9705',
      systemMessageBorderColor: '#ffffff00',
      systemMessageFontStyle: 'normal',
      systemMessageFontSize: '0.875rem',
      systemMessageAlignment: 'center',
      
      // Follow-up pill styling
      followUpPillBgColor: '#e9f0ff',
      followUpPillHoverBgColor: '#c7dbff',
      followUpPillTextColor: '#0055cc',
      followUpPillBorderColor: '#a8c7fa',
      followUpPillBorderRadius: '1rem',
      
      // Message bubbles
      userMessageBg: '#4f46e5',
      userMessageText: '#ffffff',
      aiMessageBg: '#f9fafb',
      aiMessageText: '#1f2937',
      
      // Font settings
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
      fontSize: '15px',
      headerFontSize: '18px',
      
      // Button styles
      buttonBg: '#4f46e5',
      buttonText: '#ffffff',
      buttonHoverBg: '#4f46e5',
      
      // Input styles
      inputBg: '#ffffff',
      inputText: '#1f2937',
      inputBorder: '#e5e7eb'
    }
  };

  const handleCTAButtonClick = () => {
    window?.gtag('event', 'koko_landing_cta_click', {
      event_category: 'CTA',
      event_label: 'Koko Landing CTA',
    });

    window.location.href = 'https://community.flockx.io';
  };

  return (
    <BaseLayout
      mainSectionClass="relative flex-grow h-full w-full"
      hideHeroImage
      customHeaderClass="fixed w-full z-50 bg-transparent"
    >

      <SEO
        title="Welcome to SXSW. Taste of KOKO"
        keywords={SEO_KEYWORDS}
        description="Explore the city's vibrant scene with Koko's AI-powered recommendations. The ultimate guide to Austin's flavors and culture."
        schemaMarkup={schemaMarkup}
      />

<div className="relative h-screen">
      {/* Background with class for media query handling */}
      <div className="hero-background club-koko" />

      {/* Content container with existing Tailwind classes */}
      <div className="absolute inset-0 z-10 flex pt-24">
        <div className="w-full flex flex-col md:flex-row">
          <div className="w-full px-2 lg:px-6 md:pl-24 lg:pl-32 
            flex items-end md:items-center 
            pb-[15vh] md:pb-0"
          >
            <div className="w-full mx-auto md:mx-0 
              max-w-xl md:max-w-2xl 2xl:max-w-4xl
              text-center md:text-left"
            >
              <h1 className="text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl 
                font-bold tracking-regular text-grey-400 lg:text-white
                mb-4 md:mb-6"
              >
               Welcome to SXSW.<br></br>
               Taste of KOKO
              </h1>
              
              <p className="text-base md:text-lg lg:text-xl 2xl:text-2xl 
                font-regular text-grey-400 lg:text-white
                mb-6 md:mb-8 
                leading-7 md:leading-8 lg:leading-9"
              >
           Koko has been Austin's OG food blogger since 2010. <br></br>
           Discover and connect with the Austin local scene with KoKo's AI.
                </p>
              <JoinTheWaitlistKoko />
            </div>
          </div>
          <div className="w-full h-full flex-grow">
            <ChatWidget config={chatWidgetConfig} />
          </div>
        </div>
      </div>
    </div>


    </BaseLayout>
  );
}




export const query = graphql`
  query ActivityHomePageQuery {
    site {
      siteMetadata {
        description
        siteUrl
      }
    }
  }
`;
