import { v4 as uuidv4 } from 'uuid';

// Generate a UUID for messages
export const generateUUID = () => {
  return uuidv4();
};

// Get current timestamp in ISO format
export const getCurrentTimestamp = () => {
  return new Date().toISOString();
};

// Parse timestamp and handle errors
export const parseTimestamp = (timestamp) => {
  if (!timestamp) return getCurrentTimestamp();
  try {
    const date = new Date(timestamp);
    return date.toISOString();
  } catch (e) {
    console.error('Error parsing timestamp:', e);
    return getCurrentTimestamp();
  }
};

// Format timestamp for display
export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
};

// Apply theme colors as CSS variables
export const applyThemeColors = (theme) => {
  if (typeof document === 'undefined') return; // Guard for SSR
  
  const widgetElements = document.querySelectorAll('#chat-widget, #toggle-chat-button');
  
  widgetElements.forEach(element => {
    // Set up CSS custom properties
    element.style.setProperty('--primary-color', theme.primaryColor);
    element.style.setProperty('--primary-hover', theme.primaryHoverColor);
    element.style.setProperty('--bg-color', theme.bgColor);
    element.style.setProperty('--text-color', theme.textColor);
    element.style.setProperty('--border-color', theme.borderColor);
    element.style.setProperty('--message-bg', theme.messageBgColor);
    element.style.setProperty('--ai-color', theme.aiMessageColor);
    element.style.setProperty('--human-color', theme.humanMessageColor);
    element.style.setProperty('--system-color', theme.systemMessageColor);
    element.style.setProperty('--system-message-bg', theme.systemMessageBgColor);
    element.style.setProperty('--system-message-border-color', theme.systemMessageBorderColor);
    element.style.setProperty('--system-message-font-style', theme.systemMessageFontStyle);
    element.style.setProperty('--system-message-font-size', theme.systemMessageFontSize);
    element.style.setProperty('--system-message-alignment', theme.systemMessageAlignment);
    element.style.setProperty('--follow-up-pill-bg', theme.followUpPillBgColor);
    element.style.setProperty('--follow-up-pill-hover-bg', theme.followUpPillHoverBgColor);
    element.style.setProperty('--follow-up-pill-text-color', theme.followUpPillTextColor);
    element.style.setProperty('--follow-up-pill-border-color', theme.followUpPillBorderColor);
    element.style.setProperty('--follow-up-pill-border-radius', theme.followUpPillBorderRadius);
    element.style.setProperty('--gradient-start', theme.gradientStart);
    element.style.setProperty('--gradient-end', theme.gradientEnd);
  });
}; 