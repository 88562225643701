import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ChatWidgetContainer from './ChatWidgetContainer';
import { defaultConfig } from './config';
import './styles.css';

const ChatWidget = ({ config = {} }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentStreamingMessage, setCurrentStreamingMessage] = useState(null);
  const [connectionError, setConnectionError] = useState(null);
  const wsServiceRef = useRef(null);
  const hasShownInitialConnection = useRef(false);

  const mergedConfig = {
    ...defaultConfig,
    ...config,
    theme: {
      ...defaultConfig.theme,
      ...(config.theme || {}),
    },
    position: {
      ...defaultConfig.position,
      ...(config.position || {}),
    },
  };

  const toggleChat = (show) => {
    setIsVisible(show);
  };

  useEffect(() => {
    // Import WebSocketService dynamically to avoid SSR issues
    import('./WebSocketService').then(({ WebSocketService }) => {
      const wsService = new WebSocketService(mergedConfig.websocketUrl);
      wsServiceRef.current = wsService;

      // Setup message handler
      wsService.setMessageHandler((data) => {
        try {
          // Process received messages
          const parsed = JSON.parse(data);
          console.log('📨 Received message:', {
            type: parsed.type,
            source: parsed.source,
            role_type: parsed.role_type,
            text: parsed.text,
            metadata: parsed.metadata,
          });

          // Handle different message types
          processMessage(parsed);
        } catch (error) {
          console.error('❌ Error handling message:', error);
        }
      });

      // Connect WebSocket
      wsService.connect(mergedConfig.sessionId);

      // Setup reconnection on network status change
      const handleOnline = () => {
        console.log('🌐 Network connection restored');
        if (!wsService.isConnected()) {
          console.log('🔄 Attempting to reconnect WebSocket');
          wsService.connect(mergedConfig.sessionId);
        }
      };

      window.addEventListener('online', handleOnline);

      // Cleanup on unmount
      return () => {
        window.removeEventListener('online', handleOnline);
        if (wsService) {
          wsService.disconnect();
        }
      };
    });
  }, [mergedConfig.websocketUrl, mergedConfig.sessionId]);

  const handleFollowUpDebug = (parsed) => {
    // Check if this is a message completion
    if (parsed.metadata?.source === 'stream_complete') {
      console.log(
        'Stream complete message received with metadata:',
        parsed.metadata
      );
      console.log('Follow-up questions:', parsed.metadata.follow_up_questions);
    }

    // Check if we have metadata.follow_up_questions in any messages
    if (parsed.metadata?.follow_up_questions) {
      console.log(
        'Message has follow-up questions:',
        parsed.metadata.follow_up_questions
      );
    }
  };

  const processMessage = (parsed) => {
    const { MessageEvents, RoleType } = require('./constants');

    // Skip heartbeat messages
    if (parsed.type === MessageEvents.HEARTBEAT) {
      console.log('💓 Heartbeat received');
      return;
    }

    console.log('🔍 FULL PARSED MESSAGE:', JSON.stringify(parsed, null, 2));

    // Create message object from parsed data with explicit logging
    const message = {
      id: parsed.id,
      role_type: parsed.role_type?.toLowerCase(),
      message: parsed.text || '',
      twin_id: parsed.twin_id,
      created_at: parsed.created_at,
      metadata: parsed.metadata || {},
    };

    console.log(
      '🔄 Converted message object:',
      JSON.stringify(message, null, 2)
    );

    // CRITICAL DEBUG: Check for follow-up questions in every message
    if (parsed.metadata && 'follow_up_questions' in parsed.metadata) {
      console.log(
        '🎯 FOUND FOLLOW-UP QUESTIONS:',
        parsed.metadata.follow_up_questions
      );

      // Store this message directly with follow-up questions preserved
      if (
        parsed.metadata.source === 'stream_complete' ||
        parsed.metadata.is_complete
      ) {
        console.log(
          '📌 This is a FINAL message with follow-up questions - adding to messages'
        );

        // Create final message with complete data
        const finalMessage = {
          ...message,
          message: parsed.text || '',
          metadata: {
            ...parsed.metadata,
            follow_up_questions: parsed.metadata.follow_up_questions,
            is_complete: true,
          },
        };

        console.log('📦 Final message object with follow-ups:', finalMessage);

        // Add directly to message state
        setMessages((prev) => [...prev, finalMessage]);

        // Clear streaming state if applicable
        if (currentStreamingMessage) {
          setCurrentStreamingMessage(null);
        }

        // Skip regular processing
        return;
      }
    }

    // Handle CONTROL messages
    if (parsed.type === MessageEvents.CONTROL) {
      console.log('🎮 Control message received');
      // Only add connection message if it hasn't been shown before
      if (
        message.message.includes('Connected to chat') &&
        !hasShownInitialConnection.current
      ) {
        hasShownInitialConnection.current = true;
        setMessages((prev) => [
          ...prev,
          {
            ...message,
            isControl: true,
          },
        ]);
      } else if (!message.message.includes('Connected to chat')) {
        // Show other control messages normally
        setMessages((prev) => [
          ...prev,
          {
            ...message,
            isControl: true,
          },
        ]);
      }
      return;
    }

    // Handle streaming messages
    if (message.metadata?.is_start) {
      console.log('🎬 Starting new stream:', {
        id: message.id,
        role: message.role_type,
      });
      setCurrentStreamingMessage(message);
    } else if (
      message.metadata?.is_complete ||
      message.metadata?.source === 'stream_complete'
    ) {
      console.log('✅ Stream complete:', {
        id: message.id,
        role: message.role_type,
        metadata: message.metadata,
      });

      if (currentStreamingMessage) {
        // Update and finalize the streaming message
        const finalMessage = {
          ...currentStreamingMessage,
          message: message.message || currentStreamingMessage.message,
          metadata: {
            ...message.metadata,
            is_complete: true,
          },
        };

        setMessages((prev) => [...prev, finalMessage]);
        setCurrentStreamingMessage(null);
      } else {
        // Handle as regular message if no streaming context
        setMessages((prev) => [...prev, message]);
      }
    } else if (message.metadata?.is_streaming) {
      // This is an intermediary streaming update
      if (currentStreamingMessage) {
        setCurrentStreamingMessage({
          ...currentStreamingMessage,
          message: message.message,
        });
      } else {
        setCurrentStreamingMessage(message);
      }
    } else {
      // Regular message
      console.log('📝 Regular message:', {
        id: message.id,
        role: message.role_type,
      });
      setMessages((prev) => [...prev, message]);
    }

    // Add this to your existing message processing logic
    handleFollowUpDebug(parsed);

    // When adding to messages state, ensure metadata is preserved
    if (parsed.metadata?.follow_up_questions) {
      console.log('Preserving follow-up questions in message state');
    }

    // When processing is complete for a message
    if (parsed.metadata?.source === 'stream_complete') {
      // Make sure to include the metadata when adding the message
      setMessages((prev) => [
        ...prev,
        {
          ...message,
          metadata: parsed.metadata, // Ensure this contains follow_up_questions
        },
      ]);

      // Log the last message to verify metadata
      setTimeout(() => {
        console.log('Last message in state:', messages[messages.length - 1]);
      }, 100);
    }
  };

  const handleSubmit = (text) => {
    if (!text.trim()) return;

    console.log('🎯 Submit handler called with message:', text);

    if (wsServiceRef.current) {
      if (!wsServiceRef.current.isConnected()) {
        console.log('🔄 WebSocket disconnected, attempting to reconnect');
        try {
          wsServiceRef.current.connect(mergedConfig.sessionId);
          console.log('🟢 Reconnected successfully');
        } catch (error) {
          console.error('❌ Failed to reconnect:', error);
          setConnectionError('Failed to connect. Please try again.');
          return;
        }
      }

      sendMessage(text);
    } else {
      setConnectionError(
        'Chat service not initialized. Please reload the page.'
      );
    }
  };

  const sendMessage = (text) => {
    const { generateUUID } = require('./utils');
    const { Sources, MessageEvents, RoleType } = require('./constants');

    console.log('🚀 Preparing to send message:', text);

    const message_event = {
      id: generateUUID(),
      source: Sources.WEBSOCKET,
      type: MessageEvents.MESSAGE,
      role_type: RoleType.HUMAN,
      text: text,
      twin_id: mergedConfig.twinId,
      session_id: mergedConfig.sessionId,
      created_at: new Date().toISOString(),
      metadata: {},
    };

    console.log('📦 Message object:', message_event);

    // Add message to local state first for immediate feedback
    setMessages((prev) => [
      ...prev,
      {
        id: message_event.id,
        role_type: RoleType.HUMAN.toLowerCase(),
        message: text,
        created_at: message_event.created_at,
        metadata: {},
      },
    ]);

    return wsServiceRef.current.send(message_event);
  };

  const handleFollowUpClick = (question) => {
    console.log('👆 Follow-up question clicked:', question);

    // Set input and automatically submit
    const messageData = {
      type: 'message',
      role_type: 'human',
      text: question,
      twin_id: mergedConfig.twinId,
      session_id: mergedConfig.sessionId,
      created_at: new Date().toISOString(),
      metadata: {},
    };

    if (wsServiceRef.current) {
      wsServiceRef.current.send(JSON.stringify(messageData));
    }
  };

  return (
    <ChatWidgetContainer
      config={mergedConfig}
      isVisible={isVisible}
      toggleChat={toggleChat}
      messages={messages}
      streamingMessage={currentStreamingMessage}
      onSubmit={handleSubmit}
      onFollowUpClick={handleFollowUpClick}
      connectionError={connectionError}
    />
  );
};

ChatWidget.propTypes = {
  config: PropTypes.object,
};

export default ChatWidget;
